import { __ } from '../i18n';
import { checkIfItIsClientOrSchool } from '../utils';

export const headers = (organizationType, entityType, newStudentFlowFlag) => {
  if (entityType === 'STAFF') {
    return [
      __('Name'),
      __('Description/Role'),
      __('Email Staff'),
      __('Phone Staff')
    ];
  }

  if (checkIfItIsClientOrSchool(organizationType) === 1) {
    const headersTitle = [
      __('Name'),
      __('Group'),
      __('Email Student'),
      __('Phone Student'),
      __('Email Pedagogical Responsible'),
      __('Phone Pedagogical Responsible'),
      __('Email Financial Responsible'),
      __('Phone Financial Responsible')
    ];

    if(newStudentFlowFlag) {
      const newValuesStudent = [__("Student's CPF"), __("Date of birth")]
      headersTitle.splice(2, 0, ...newValuesStudent)
    }

    return headersTitle
  }

  return [
    __('Name'),
    __('Group'),
    __('Email'),
    __('Phone')
  ];
};

export const data = (organizationType, entityType, newStudentFlowFlag) => {
  if (entityType === 'STAFF') {
    return [
      [__('John Appleseed'), __('P. E. Teacher'), __('john.appleseed@example.com'), __('(707) 555-1234')],
      [__('Mary Knight'), __('Math Teacher'), __('mary.knight@example.com'), __('(708) 550-1235')],
      [__('Jack Littleton'), '', __('jack.littleton@example.com'), __('(709) 550-1236')],
      [__('Kate Goldman'), __('Secretary'), __('kate.goldman@example.com'), __('(710) 550-1237')],
    ];
  }

  if (checkIfItIsClientOrSchool(organizationType) === 1) {
    let dataValues = [
      [__('John Appleseed'), __('1st Grade'), __('john.appleseed@example.com'), __('(707) 555-1234'), __('dad.appleseed@example.com'), __('(707) 551-1234'), __('mom.appleseed@example.com'), __('(707) 552-1234')],
      [__('Mary Knight'), __('1st Grade'), __('mary.knight@example.com'), __('(708) 550-1235'), __('dad.knight@example.com'), __('(708) 551-1235'), __('mom.knight@example.com'), __('(708) 552-1235')],
      [__('Jack Littleton'), __('2nd Grade'), __('jack.littleton@example.com'), __('(709) 550-1236'), __('dad.littleton@example.com'), __('(709) 551-1236'), __('mom.littleton@example.com'), __('(709) 552-1236')],
      [__('Kate Goldman'), __('2nd Grade'), __('kate.goldman@example.com'), __('(710) 550-1237'), __('dad.goldman@example.com'), __('(710) 551-1237'), __('mom.goldman@example.com'), __('(710) 552-1237')],
    ];
    
    const cpfs = ['134.241.170-64', '127.375.840-43', '037.737.030-40', '950.849.550-28']
    if(newStudentFlowFlag) {
      dataValues = dataValues.map((dataValue, i) => {
        const newValuesStudent = [cpfs[i], '01/01/2018']
        dataValue.splice(2, 0, ...newValuesStudent)
        return dataValue
      })
    }
    return dataValues
  }

  return [
    [__('John Appleseed'), __('1st Grade'), __('john.appleseed@example.com'), __('(707) 555-1234')],
    [__('Mary Knight'), __('1st Grade'), __('mary.knight@example.com'), __('(708) 550-1235')],
    [__('Jack Littleton'), __('2nd Grade'), __('jack.littleton@example.com'), __('(709) 550-1236')],
    [__('Kate Goldman'), __('2nd Grade'), __('kate.goldman@example.com'), __('(710) 550-1237')],
  ];
};

const sample = {
  headers,
  data
};

export default sample;
