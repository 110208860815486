import moment from 'moment';

/* eslint-disable no-cond-assign */
const merge = require('deepmerge');
const PhoneNumber = require('awesome-phonenumber');
const levenshtein = require('fast-levenshtein');
const validate = require('../lib/validate');
const { __ } = require('../i18n');
import * as utils from '../utils';


const placeUnique = function (obj, field, param, existing, mergeOptions) {
  const existingIndex = (param) ? existing.indexOf(field[param]) : existing.indexOf(field);
  if (existingIndex > -1) {
    obj[existingIndex] = typeof field === 'string' ? field : merge(obj[existingIndex], field, mergeOptions);
  } else {
    existing.push(param ? field[param] : field);
    obj.push(field);
  }
};

const place = function (obj, field, param, existing) {
  if (Array.isArray(field)) {
    field.filter(n => n).forEach(a => placeUnique(obj, a, param, existing));
  } else if (field) {
    placeUnique(obj, field, param, existing);
  }
};

/**
 * @param {string[]} array
 * @param {string} element
 */
function arrayIncludesIgnoreCase(array, element) {
  for (const item of array) {
    if (new RegExp(`\\b${item}\\b`, 'i').test(`${element}`)) return true;
  }
  return false;
}

/**
 * @param {string[]} row
 * @param {boolean} clearTags
 * @param {{
 *  text: any;
 *  value: string;
 *  options: string[];
 * }[]} columns
 */
const parseHeaders = function (row, clearTags, columns) {
  const headers = [];

  row.forEach((value) => {
    let field = value;
    if (Array.isArray(value)) field = value[0];
    field = field && field.toLowerCase();

    let matched = false;
    columns.forEach((column) => {
      if (arrayIncludesIgnoreCase(column.options, field)) {
        matched = true;
        if (column.value === 'email' || column.value === 'phone') {
          const parameters = { type: column.value, tags: [] };
          if (!clearTags) parameters.tags = value.slice(-1);
          headers.push({ column: 'address', parameters });
        } else {
          headers.push({ column: column.value });
        }
      }
    });
    if (!matched) headers.push({ column: '' });
  });
  return headers;
};
export const headers = parseHeaders;

/*const extractEmails = export const extractEmails = function (value) {
  return value.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
};*/

export const validateEmail = function (value) {
  if (value === '') return null;
  if (value?.split(' ')?.length > 1) return false;
  const [err] = validate.Checkit.checkSync('email', value, ['email']);

  if (err) {
    return false;
  }
  return value;
};

export const validatePhone = function (prefix, value, returnValid = false) {
  if (value === '') return true;

  const val = value?.replace(/[^0-9\+]+/g, '');

  if (!val) return false;

  const pn = new PhoneNumber(val.startsWith('+') ? val : ('+' + prefix + val), 'ZZ');
  if (!pn.isMobile()) return false;

  return (returnValid) ? pn.getNumber('international') : value;
};

function isDateFormat(str) {
  const parts = str.split('/');
  if (parts.length !== 3) return false;

  if(parts.some(part => !part.length || part.length > 4)) return false;

  if (isNaN(parts[0]) || isNaN(parts[1]) || isNaN(parts[2])) return false;

  return true;
}

export const extractValue = function (value) {
  if (/,|;|\//.test(value)) {
    const isDateValue = isDateFormat(value)
    const sanitizeValue = value.split(/,|;|\/|\t+/).filter(elementValue => (elementValue !== '' ? elementValue.trim() : false));
    const valuesArray = isDateValue ? value : sanitizeValue

    return valuesArray.length === 1 ? valuesArray[0] : valuesArray;
  }
  
  return value;
};

export const parseBoolean = function (value) {
  return /true|1|yes|sim|sí|oui|ja/.test(value);
};

export const parseType = function (value, def = 'student') {
  if (value.startsWith('student')) {
    return 'student';
  } else if (value.startsWith('staff')) {
    return 'staff';
  } else if (value.startsWith('admin')) {
    return 'admin';
  }
  return def;
};

const concatMerge = function (destinationArray, sourceArray, mergeOptions) {
  const param = mergeOptions ? mergeOptions.param : null;

  return destinationArray.concat(sourceArray.filter((item) => {
    if (param && typeof item === 'object' && param in item) {
      const found = destinationArray.findIndex(dest => dest[param] === item[param]);
      if (found > -1) {
        destinationArray[found] = merge(destinationArray[found], item);
      }
      return found === -1;
    }

    return destinationArray.indexOf(item) < 0;
  }));
};

const parseAddress = function (field, params, prefix = null) {
  let fields;
  if (params.type === 'email') {
    fields = validateEmail(field);
  } else {
    fields = validatePhone(prefix, field, true);
  }

  if (fields === false) {
    fields = field;
    params.invalid = true;
  }

  if (!fields) return null;

  return Object.assign({}, { address: fields }, params);
};

const parseAddresses = function (field, params, prefix = null) {
  if (Array.isArray(field)) {
    return field.map(f => parseAddress(f, params, prefix));
  }
  return parseAddress(field, params, prefix);
};

const trimValues = function (value) {
  if (typeof value === 'string') {
    return value.trim();
  } else if (typeof value === 'object') {
    value.forEach((item, i) => {
      value[i] = trimValues(item);
    });
    return value;
  }
  return value;
};

export const arrayToJSON = function (raw, headers, prefix, type, columns) {
  if (!headers) {
    headers = parseHeaders(raw[0], false, columns);
  }

  const body = raw;

  const result = [];

  let groupBy = 'fullname';
  if (headers.find(header => header.column === 'eid')) {
    groupBy = 'eid';
  }

  body.forEach((row) => {
    const entity = {
    };
    const addresses = [];
    const groups = [];

    if (type) entity.type = type;

    row.forEach((field, j) => {
      const header = headers[j];
      switch (header.column) {
        case 'address': {
          const address = parseAddresses(Array.isArray(field) ? field.map(f => f?.toLowerCase()) : field?.toLowerCase(), header.parameters, prefix);
          if (!entity.addresses) {
            entity.addresses = [];
          }
          place(entity.addresses, address, 'address', addresses);
          break;
        }
        case 'group': {
          if (!entity.groups) {
            entity.groups = [];
          }
          place(entity.groups, field, null, groups);
          break;
        }
        case 'type': {
          entity.type = parseType(field);
          break;
        }
        case 'see_all':
        case 'invisible':
        case 'disabled': {
          entity[header.column] = Number(parseBoolean(field));
          break;
        }
        default: {
          entity[header.column] = field;
          break;
        }
      }
    });
    result.push(entity);
    // placeUnique(result, entity, groupBy, indexes, { arrayMerge: concatMerge, param: 'address' });
  });

  return result;
};
/*
export const findAndSortDuplicates = function (array, headers) {
  const compare = [false];

  const eidIndex = headers.findIndex(header => header.column === 'eid');
  const fullnameIndex = headers.findIndex(header => header.column === 'fullname');

  const sorted = array
    .sort((a, b) => (a[fullnameIndex] > b[fullnameIndex] ? 1 : -1))
    .sort((a, b) => ((a[eidIndex] !== '' && a[eidIndex] > b[eidIndex]) ? 1 : -1));

  sorted.reduce((a, b) => {
    compare.push(
      (a[eidIndex] !== '' && a[eidIndex] === b[eidIndex]) ||
      levenshtein.get(a[fullnameIndex], b[fullnameIndex]) < 2
    );
    return b;
  });
  // Add last item as false
  compare.push(false);

  return { sorted, compare };
};
*/
const checkValid = (cell, type, prefix) => (
  type === 'email' ? validateEmail(cell) : validatePhone(prefix, cell)
);

export const errorDateOfBirthValidation = (date) => {
  const isDateOfBirthValid = moment(date, 'DD/MM/YYYY', true).isValid()
  const isDateOfBirthGreaterThanToday = moment(date, 'DD/MM/YYYY').isAfter(moment())
  const isDateOfBirthLessThanMinimumYear = moment(date, 'DD/MM/YYYY').year() < moment().year(1924).year()
  const errorDateOfBirth = !isDateOfBirthValid || isDateOfBirthGreaterThanToday || isDateOfBirthLessThanMinimumYear

  return errorDateOfBirth;
}

export const findErrors = function (array, headers, prefix = '') {
  const errors = {};
  const toCheck = ['address', 'fullname', 'eid', 'disabled', 'invisible', 'see_all', 'birth_date', 'document_number'];
  const disabledValidation = ['yes', 'no', 'sim', 'não', '0', '1'];

  const addressIndexes = headers.reduce((a, e, i) => {
    if (toCheck.indexOf(e.column) !== -1) {
      a.push(i);
    }
    return a;
  }, []);

  array.forEach((row, i) => {
    const checkers = { fullname: false, eid: false, hasfullname: false, haseid: false, cells: [] };
    
    row?.forEach((cell, j) => {
      let errorRow = [];
      const hasDateOfBirthError = headers[j].column === 'birth_date' && errorDateOfBirthValidation(cell)
      const hasDocumentNumberError = headers[j].column === 'document_number' && (utils.validateCPF(cell) === false)
    

      if (addressIndexes.indexOf(j) > -1 && headers[j].parameters) {
        const type = headers[j].parameters.type;

        const cellArray = Array.isArray(cell) ? cell : [cell];
        
        cellArray.forEach((c, k) => { 
          if (checkValid(c, type, prefix) === false) errorRow.push(k);
        });
        
      } else if (addressIndexes.indexOf(j) > -1 && !headers[j].parameters) {
        if (['disabled', 'invisible', 'see_all'].includes(headers[j].column) && !disabledValidation.includes(cell)) {
          errorRow = [j];
        } else if (headers[j].column === 'fullname' || headers[j].column === 'eid') {
          checkers['has' + headers[j].column] = true;
          if (!cell) {
            checkers[headers[j].column] = true;
            checkers.cells.push(j);
          }
          if (checkers.fullname && checkers.eid) {
            errors[i] = {};
            checkers.cells.forEach((jc) => { errors[i][jc] = [jc]; });
          }
        } else if (cell !== '' && (hasDateOfBirthError || hasDocumentNumberError || !cell)) {
          errorRow = [j]
        }
      }

      if (errorRow !== null && (typeof errorRow === 'number' || errorRow.length)) {
        if (i in errors && !(j in errors[i])) {
          errors[i][j] = errorRow;
        } else {
          errors[i] = {};
          errors[i][j] = errorRow;
        }
      }
    });

    if (checkers.cells.length === 1) {
      if (!errors[i]) errors[i] = {};
      errors[i][checkers.cells[0]] = [checkers.cells[0]];
      
      if ((!checkers.eid && checkers.fullname && checkers.haseid) ||
      (checkers.eid && !checkers.fullname && checkers.hasfullname)) {
        delete errors[i][checkers.cells[0]];
      }
    }
  });
  return errors;
};


export const csvToArray = function (strData, strDelimiter = ',') {
  let data = strData;
  let delimiter = strDelimiter;

  if (!data) return [];

  // Remove the first line if it contains "sep=..."
  if (data.match(/^sep=.*(\n|\r)?/)) data = data.replace(/^sep=.*(\n|\r)?/, '');

  if (/\t/.test(data)) {
    delimiter = '\t';
  } else if (/;/.test(data)) {
    delimiter = ';';
  }

  // Create a regular expression to parse the CSV values.
  const objPattern = new RegExp(
    (
    // Delimiters.
      '(\\' + delimiter + '|\\r?\\n|\\r|^)' +

    // Quoted fields.
    '("([^"]*(?:""[^"]*)*)"[^\\' + delimiter + '\\r\\n]*|' +

    // Standard fields.
    '([^\\' + delimiter + '\\r\\n]*))'
    ),
    'gi'
  );


  // Create an array to hold our data. Give the array
  // a default empty first row.
  /** @type {string[][]} */
  const arrData = [[]];

  // Create an array to hold our individual pattern
  // matching groups.
  let arrMatches = null;

  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while (arrMatches = objPattern.exec(data)) {
    // Get the delimiter that was found.
    const strMatchedDelimiter = arrMatches[1];


    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (
      strMatchedDelimiter.length &&
      strMatchedDelimiter !== delimiter
    ) {
      // If row only has row delimiter, ignore row
      if (arrMatches[0].length <= 1 && !arrMatches[0].match(/\n/g).length) {
        continue;
      }

      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);
    }

    let strMatchedValue;

    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[3] != undefined) {
      // We found a quoted value. When we capture
      // this value, unescape double quotes if they're
      // in the start and in the end.

      strMatchedValue = arrMatches[2].replace(
        /^\s*"(.*)"\s*$/gi,
        '$1'
      );
    } else {
      // We found a non-quoted value.
      strMatchedValue = arrMatches[4];
    }

    // Check if there are 2 or more different separators
    if(_.uniq(strMatchedValue.match(/,|;|\//gi)).length > 1)
      throw { name: 'SeparatorError', message: __('Fields with multiple values must contain only one type of separator (“/”, “;” or “,”)') };

    strMatchedValue = typeof extractValue(strMatchedValue) === 'string' ?
      extractValue(strMatchedValue).replace(/\s+/g, ' ').trim() :
      extractValue(strMatchedValue).map(val => val.replace(/\s+/g, ' ').trim());

    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue);
  }

  if (arrData[arrData.length - 1].length === 1 && !arrData[arrData.length - 1][0]) {
    arrData.pop();
  }

  // Return the parsed data.
  return (arrData);
};


const mergeRows = function (headers, row1, row2) {
  const newRow = [];
  const unique = ['fullname', 'eid', 'type', 'invisible', 'see_all', 'disabled', 'birth_date', 'document_number'];

  headers.forEach((header, i) => {
    let a = row1[i];
    let b = row2[i];
    const toLower = address => address.toLowerCase();
    const comparison = (header.column === 'address' && !Array.isArray(a) && !Array.isArray(b)
      ? toLower(a) !== toLower(b) : a !== b);
    if (a && b && comparison && unique.indexOf(header.column) === -1) {
      if (!Array.isArray(a)) a = [a];
      if (!Array.isArray(b)) b = [b];
      if (header.column === 'address') {
        a = a.map(toLower);
        b = b.map(toLower);
      }
      const c = _.uniqBy([...a, ...b]).filter(ad => ad);
      newRow.push(c);
    } else {
      newRow.push(a || b);
    }
  });

  return newRow;
};

export const mergeAllRows = function (headers, rows) {
  let i = 0;
  let newRow = rows[i];

  while (rows[i + 1] !== undefined) {
    newRow = mergeRows(headers, newRow, rows[i + 1]);
    i += 1;
  }

  return newRow;
};

export const joinUniqueKeys = function (array, headers) {
  const keys = [];
  const eidIndex = headers.findIndex(header => header.column === 'eid');
  const fullnameIndex = headers.findIndex(header => header.column === 'fullname');

  if (eidIndex > -1) keys.push('eid');
  if (fullnameIndex > -1) keys.push('fullname');

  keys.forEach((key) => {
    const index = key === 'eid' ? eidIndex : fullnameIndex;

    array = array.map((row) => {
      if (!row) return row;

      const value = row[index];

      if (typeof value !== 'string') row[index] = value.join('/');

      return row;
    });
  });

  return array;
};

export const findDuplicates = function (array, headers) {
  const keys = [];
  const eidIndex = headers.findIndex(header => header.column === 'eid');
  const fullnameIndex = headers.findIndex(header => header.column === 'fullname');
  const documentNumberIndex = headers.findIndex(header => header.column === 'document_number');
  const dateOfBirthIndex = headers.findIndex(header => header.column === 'birth_date');

  return array.reduce((prev, item, i) => {
    const index = { 
      eid: item?.[eidIndex], 
      fullname: item?.[fullnameIndex],
      document_number: item?.[documentNumberIndex],
      birth_date: item?.[dateOfBirthIndex],
      othersFields: {...item}
    };

    const allFieldsEmptyExceptName = (object) => [eidIndex, documentNumberIndex, dateOfBirthIndex].every(i => !object?.[i])

    const key = keys.findIndex((key) => {
      const eid1 = key.eid?.toLowerCase()?.replace(/\s+/g, ' ')?.trim()
      const eid2 = index.eid?.toLowerCase()?.replace(/\s+/g, ' ')?.trim()
      
      const documentNumber1 = key.document_number?.replace(/\.|-|\s/g, '')?.trim()
      const documentNumber2 = index.document_number?.replace(/\.|-|\s/g, '')?.trim()

      const dateOfBirth1 = key.birth_date
      const dateOfBirth2 = index.birth_date

      const fullname1 = key.fullname?.toLowerCase()?.replace(/\s+/g, ' ')?.trim() || ''
      const fullname2 = index.fullname?.toLowerCase()?.replace(/\s+/g, ' ')?.trim() || ''

      const isDuplicatedEid = eid1 && eid2 && eid1 !== '' && (eid1 === eid2)
      const isSameName = fullname1 !== '' && (levenshtein.get(fullname1, fullname2) === 0)
      const isSimilarName = fullname1 !== '' && (!isSameName && levenshtein.get(fullname1, fullname2) < 2)
      const isDuplicatedDateOfBirth = dateOfBirth1 && dateOfBirth2 && dateOfBirth1 !== '' && (dateOfBirth1 === dateOfBirth2)
      const isDuplicatedDocumentNumber = documentNumber1 && documentNumber2 && documentNumber1 !== '' && (documentNumber1 === documentNumber2)

      const allFieldsEmpty1 = (isSameName || isSimilarName) && allFieldsEmptyExceptName(index?.othersFields)
      const allFieldsEmpty2 = (isSameName || isSimilarName) && allFieldsEmptyExceptName(key?.othersFields)
      const hasOnlyFullnameFilled = allFieldsEmpty1 && allFieldsEmpty2

      if (isDuplicatedEid) return true
      if ((isSameName || isSimilarName) && hasOnlyFullnameFilled) return true
      if (isDuplicatedDateOfBirth && (isSameName || isSimilarName)) return true
      if (isDuplicatedDocumentNumber) return true
      
      return false
    }); 

    if (key > -1) {
      prev[key].push(i);
    } else {
      prev.push([i]);
      keys.push(index);
    }
    return prev;
  }, [])
    .filter(a => a.length > 1)
    .reduce((prev, items) => {
      prev[items[0]] = {
        items,
        removed: [],
        final: null,
        expanded: false,
        modified: {}
      };
      return prev;
    }, {});
};

export const validateDuplicates = function (headers, index, array, willValidateSimilarName=false) {
  const arrayLength = array.length - 1;

  const final = array.map((item, i) => {
    const val = [];
    const itemBasedOnIndex = index.map(uniqueItem => {
      return {
        header: headers[uniqueItem].column,
        content: item[uniqueItem].toLowerCase().replace(/\s+/g, ' ').trim()
      }
    })
    for (let n = i + 1; n <= arrayLength; n++) {
      const arrayBasedOnIndex = index.map(uniqueItem => {
        return {
          header: headers[uniqueItem].column,
          content: array[n][uniqueItem].toLowerCase().replace(/\s+/g, ' ').trim()
        }
      })

      const fullNameColumn1 = itemBasedOnIndex.find(item => item.header === 'fullname')?.content
      const fullNameColumn2 = arrayBasedOnIndex.find(item => item.header === 'fullname')?.content
      const isSameName = (fullNameColumn1 && fullNameColumn2) && (fullNameColumn1 !== '' && fullNameColumn2 !== '') && 
        levenshtein.get(fullNameColumn1, fullNameColumn2) === 0
      const isSimilarName = fullNameColumn2 !== '' && fullNameColumn1 !== '' && 
        (!isSameName && levenshtein.get(fullNameColumn1, fullNameColumn2) < 2)

      const dateOfBirth1 = itemBasedOnIndex.find(item => item.header === 'birth_date')?.content
      const dateOfBirth2 = arrayBasedOnIndex.find(item => item.header === 'birth_date')?.content
      const isSameDateOfBirth = (dateOfBirth1 && dateOfBirth2) && (dateOfBirth1 !== '' && dateOfBirth2 !== '') && 
       (dateOfBirth1 === dateOfBirth2)

      const eid1 = itemBasedOnIndex.find(item => item.header === 'eid')?.content
      const eid2 = arrayBasedOnIndex.find(item => item.header === 'eid')?.content
      const isSameEid = (eid1 !== '' && eid2 !== '') && (eid1 && eid2) ? (eid1 === eid2) : undefined

      const documentNumber1 = itemBasedOnIndex.find(item => item.header === 'document_number')?.content
      const documentNumber2 = arrayBasedOnIndex.find(item => item.header === 'document_number')?.content
      const isSameDocumentNumber = (documentNumber1 && documentNumber2) && 
       (documentNumber1 !== '' && documentNumber2 !== '') ? (documentNumber1 === documentNumber2) : undefined

      const allFieldsEmpty1 = isSameName && itemBasedOnIndex.filter(item => ['birth_date', 'document_number','eid'].includes(item.header)).every(item => item.content === '')
      const allFieldsEmpty2 = isSameName && arrayBasedOnIndex.filter(item => ['birth_date', 'document_number','eid'].includes(item.header)).every(item => item.content === '')
      const hasOnlyFullnameFilled = allFieldsEmpty1 && allFieldsEmpty2

      if(willValidateSimilarName) {
        val.push(isSimilarName)
      }

      if(isSameEid !== undefined || isSameDocumentNumber !== undefined) {
        val.push(isSameName && isSameDateOfBirth && (isSameEid || isSameDocumentNumber))
      } else {
        val.push(isSameName && isSameDateOfBirth)
      }

      console.log({
        isSameName,
        isSameDateOfBirth,
        isSameEid,
        isSameDocumentNumber,
        hasOnlyFullnameFilled
      })

      val.push(isSameName && isSameDateOfBirth && (isSameEid || isSameDocumentNumber))
      val.push(hasOnlyFullnameFilled)
      val.push(isSameEid);
      val.push(isSameDocumentNumber);
    }

    return val.filter(result => result).length === 0;
  });
  return final.filter(result => !result).length === 0;
};

export const applyPrefix = (prefix, array, headers, errors) => {
  const phoneIndexes = headers.reduce((a, e, i) => {
    if (e.column === 'address' && e.parameters.type === 'phone') {
      a.push(i);
    }
    return a;
  }, []);

  return array.map((row, i) =>
    row && row.map((cell, j) => {
      if (i in errors && errors[i][j] && phoneIndexes.indexOf(j) > -1) {
        if (Array.isArray(cell)) {
          return cell.map((c, k) => ((c !== null && c !== '' && c.trim().length && errors[i][j].indexOf(k) !== -1) ? prefix + c : c));
        }
        return (cell !== null && cell !== '' && cell.trim().length) ? prefix + cell : cell;
      }
      return cell;
    })
  );
};

export const makeFile = (type, headers, data) => {
  let file = '';

  if (headers.length) {
    file += headers.join(',') + '\n';
  }

  if (data.length) {
    data.forEach((line, i) => {
      file += line.join(',');

      if (i < data.length - 1) {
        file += '\n';
      }
    });
  }

  return `data:${type};charset=utf-8,${encodeURIComponent(file)}`;
};

const exports = {
  headers,
  // extractEmails,
  validateEmail,
  validatePhone,
  extractValue,
  parseBoolean,
  parseType,
  arrayToJSON,
  // findAndSortDuplicates,
  findErrors,
  csvToArray,
  mergeAllRows,
  joinUniqueKeys,
  findDuplicates,
  validateDuplicates,
  applyPrefix,
  makeFile,
  errorDateOfBirthValidation
};

export default exports;
